<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
            v-model="dialogEdit"
            max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="addItem"
                    >
                    Добавить группу
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Название"
                                v-model="editedItem.name"
                                @keyup.enter="save"
                                autofocus
                                @change="haveEditorChanges = true"
                            >
                            </v-text-field>
                        </v-card-text>
                    </v-card>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление группы</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить выбранную группу?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="upItem(item)"
                title="Переместить выше"
                v-show="item.sort > 1"
            >
                mdi-arrow-up-bold
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="downItem(item)"
                title="переместить ниже"
                v-show="item.sort < maxSort"
            >
                mdi-arrow-down-bold
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить группу"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить группу"
            >
                mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "DocumentCategories",
    data() {
        return {
            list: [],
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Название',
                    sortable: false,
                },
                {
                    value: 'count',
                    text: 'Количество документов',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            maxSort: 0,
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [5, 10, 20, -1]
            },
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                sort: 0,
            },
            dialogRemove: false,
            itemToRemove: -1,
            haveEditorChanges: false,
        };
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.getList(this.pagination.page);
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новая группа' : 'Редактирование группы #' + this.editedItem.id;
        },
    },
    methods: {
        tablePagination (data) {
            this.getList(data.page);
        },
        addItem () {
            this.editedItem.sort = this.maxSort + 1;
        },
        async upItem (item) {
            let item2 = null;
            this.list.forEach((singleNew, index) => {
                if (parseInt(this.list[index].sort) === parseInt(item.sort) - 1) {
                    item2 = this.list[index];
                }
            });
            if (item2 === null) {
                return;
            }
            item.sort--;
            item2.sort++;
            // TODO: better if transaction, not 2 operations
            try {
                let r = await this.$api("/admin/document_categories/" + item.id, {
                    method: "PUT",
                    body: {
                        name: item.name,
                        sort: item.sort
                    },
                });
                if (!r.error) {
                    r = await this.$api("/admin/document_categories/" + item2.id, {
                        method: "PUT",
                        body: {
                            name: item2.name,
                            sort: item2.sort
                        },
                    });
                    if (!r.error) {
                        this.getList();
                    }
                }
                if (r.error) {
                    this.$error(r.error);
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        async downItem (item) {
            let item2 = null;
            this.list.forEach((singleNew, index) => {
                if (parseInt(this.list[index].sort) === parseInt(item.sort) + 1) {
                    item2 = this.list[index];
                }
            });
            if (item2 == null) {
                return;
            }
            item.sort++;
            item2.sort--;
            // TODO: better if transaction, not 2 operations
            try {
                let r = await this.$api("/admin/document_categories/" + item.id, {
                    method: "PUT",
                    body: {
                        name: item.name,
                        sort: item.sort
                    },
                });
                if (!r.error) {
                    r = await this.$api("/admin/document_categories/" + item2.id, {
                        method: "PUT",
                        body: {
                            name: item2.name,
                            sort: item2.sort
                        },
                    });
                    if (!r.error) {
                        this.getList();
                    }
                }
                if (r.error) {
                    this.$error(r.error);
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getList(pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/document_categories?${new URLSearchParams({
                    pg,
                    on,
                })}`);
                this.maxSort = 0;
                list.forEach((singleNew, index) => {
                    list[index].count = (parseInt(list[index].count) > 0)?parseInt(list[index].count):'';
                    if (list[index].sort > this.maxSort) {
                        this.maxSort = list[index].sort;
                    }
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                let r = await this.$api(`/admin/document_categories/${item.id}`);
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            if (parseInt(item.count) > 0) {
                this.$error('Группа используется и не может быть удалена');
                return;
            }
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/admin/document_categories/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save () {
            if (this.editedItem.name.length == 0) {
                this.$error('Название не должно быть пустым');
                return;
            }
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/document_categories/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            sort: this.editedItem.sort
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/admin/document_categories", {
                        method: "POST",
                        body: {
                            name: this.editedItem.name,
                            sort: this.editedItem.sort,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>
